/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */
//// CONTROLLER ////
(function () {
  'use strict';

  angular.module('comcast.lite').controller('SpotRejectionsDialogController', ['$state', '$mdDialog', 'LiteDeliveryResourceFactory', 'SystemEventsResourceFactory', 'EnumService', 'loginService', '$q', '$filter', 'id', 'type', function ($state, $mdDialog, LiteDeliveryResourceFactory, SystemEventsResourceFactory, enumService, loginService, $q, $filter, id, type) {
    /* PRIVATE : DATA */
    //Declare all private variables here

    /*
     * This is the `vm` object. It is a direct reference to the controller
     * which acts as our 'view-model' in angular. It also limits our need
     * to be accessing $scope directly. */

    /*jshint validthis:true*/
    var vm = this;
    /* BINDABLE : DATA */

    vm.spot = null;
    vm.selectedEvent = null;
    vm.updatedTime = null;
    vm.events = null;
    vm.eventTypeEnums = null;
    vm.eventTypes = ['SpotImportBatonFailureEvent', 'SpotImportCenterAudioFailureEvent', 'SpotImportFailureEvent', 'SpotImportQCRejectionEvent', 'SpotImportRejectionEvent', 'SpotImportTranscodeFailureEvent'];
    vm.sessionData = loginService.getSessionData();
    /* BINDABLE : METHODS*/

    vm.close = close;
    vm.formatEventType = formatEventType;
    vm.displayTime = displayTime;
    vm.selectEvent = selectEvent;
    /* EVENTS */
    //Register any event listeners

    /* INITIALIZATION */
    //Call controller initialization method here

    _init();
    /* IMPLEMENTATION : BINDABLE */


    function close() {
      $mdDialog.cancel();
    }

    function formatEventType(event) {
      if (event) {
        var foundEvent = $filter('filter')(vm.eventTypeEnums, {
          name: event
        }, true);
        return foundEvent[0].label;
      } else {
        return '';
      }
    }

    function displayTime(time) {
      return time ? moment.tz(time, 'YYYY-MM-DDTHH:mm:ss:SSS-ZZ', moment.tz.guess()).format('MM/DD/YY h:mm:ss A z') : '';
    }

    function selectEvent(event) {
      vm.selectedEvent = event; //Getting rid of empty event properties, like the Flex UI

      for (var i = 0; i < vm.selectedEvent.SystemEventProperties.length; i++) {
        if (vm.selectedEvent.SystemEventProperties[i].value === '') {
          vm.selectedEvent.SystemEventProperties.splice(i, 1);
        }
      }

      vm.updatedTime = vm.displayTime(vm.selectedEvent.dateUpdated);
    }
    /* IMPLEMENTATION : PRIVATE */
    // All private methods should start with '_' in order to distinguish them

    /**
     * Initialization for this controller.
     */


    function _init() {
      vm.searching = true;
      $q.all([enumService.getEnum('SecurityUser', 'notificationEvents')]).then(function (dataResults) {
        vm.eventTypeEnums = dataResults[0];

        switch (type) {
          case 'liteOrder':
            LiteDeliveryResourceFactory.get({
              id: id
            }, function (spot) {
              vm.spot = spot;
              vm.orderItem = vm.spot.ParentOrderItem ? vm.spot.ParentOrderItem.id : null;
              vm.distributionOrder = vm.spot.OGSpots[0] && vm.spot.OGSpots[0].OrderGroup ? vm.spot.OGSpots[0].OrderGroup.orderId : null;
              SystemEventsResourceFactory.getAll({
                distributionOrder: vm.distributionOrder,
                orderItem: vm.orderItem,
                spotId: vm.spot.id,
                types: vm.eventTypes
              }, function (events) {
                vm.event = events.data.rows[0];
                vm.selectEvent(vm.event);
                vm.searching = false;
              });
            });
            break;

          case 'spot':
            SystemEventsResourceFactory.getAll({
              spotId: id,
              types: vm.eventTypes
            }, function (events) {
              vm.event = events.data.rows[0];
              vm.selectEvent(vm.event);
              vm.searching = false;
            });
            break;
        }
      });
    }
  }]);
})();